.Projects {
  padding: 24px 32px;
  background-color: #1C202B;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.white {
  text-align: "center";
  color: white;
}

@media (max-width: 768px) {

  .project-image-container {
    padding: 12px;
    max-width: 100vw;
    overflow-x: scroll;
  }

  .video-popover {
    display: none;
  }

}
