.Home {
  font-family: RedHat;
}

.hero {
  margin-top: 64px;
  font-size: xx-large;
  display: flex;
  justify-content: center;
}

.cta :first-child {
  margin-right: 4px;
}

.cta :last-child {
  margin-left: 4px;
}

@media (max-width: 768px) {
  .Home .hero {
    flex-direction: column;
  }

  .left>* {
    text-align: center;
    width: 100%;
  }

  .right {
    position: absolute;
    z-index: -1;
    margin: 0;
  }

  .bold {
    font-size: 24px;
    /* Reduce font size for mobile */
  }

  h4 {
    font-size: 16px;
    /* Reduce font size for mobile */
  }

  .Space Button {
    margin: 5px 0;
    /* Increase spacing for better touch */
  }
}
