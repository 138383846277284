.Experience {
  margin-top: 32px;
  padding: 24px;
  background: linear-gradient(90deg, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title {
  color: white;
}

.Space Button {
  margin: 10px 0;
  /* Increase vertical margin for better touch accessibility */
}

/* Media queries for mobile devices */
@media (max-width: 768px) {

  /* Adjusting width of job listings to take up more space on mobile screens */
  .Experience .Space {
    width: 90%;
  }

  .Experience {
    padding: 16px;
    /* Reduce padding for mobile */
  }

  .section-title {
    font-size: 24px;
    /* Optionally, reduce the font size for mobile screens */
  }
}
